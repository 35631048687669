import * as React from "react";
import { useState } from "react";
import mqtt from "precompiled-mqtt";
import { Button, Tab, Snackbar } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import MuiAlert from '@mui/material/Alert';
import axios from "axios";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function Paho() {
  const [open, setopen] = useState(false);
  const [loading, setloading] = useState(false);
  const [message, setmessage] = useState("");
  const [severity, setseverity] = useState("error");
  const vertical = "top";
  const horizontal = "center";
  const topic = "-wp-34:94:54:61:E8:84";
  const listenService = "listenService".concat(topic);
  const postService = "postService".concat(topic);
  const notif = "notification-service";
  var options = {
    hostname: "fc61e06e9fda466eb883fa570fe337d4.s1.eu.hivemq.cloud",
    port: "8884",
    protocol: "wss",
    username: "QuantumWaterBot",
    password: "Quantum#123",
    path: "/mqtt",
    clientId: "testing123",
  };

  // initialize the MQTT client
  var client = mqtt.connect(options);

  client.on("connect", function () {
    // console.log("Connected");
  });

  client.on("error", function (error) {
    console.log(error);
  });

  client.on("message", function (topic, message) {
    // called each time a message is received
    console.log("Received message:", topic, message.toString());
    const mess = JSON.parse(message);
    console.log(mess);
    if(topic === notif){
      if (mess?.status === "on") {
        setmessage("Lights are on");
        setseverity("success")
      }
      else if ( topic==="register-service" && mess.clientId ==="wp-34:94:54:61:E8:84"){
        telegramAPI();
      }
      else{
        setmessage("Lights are off")
        setseverity("error")
      }
      setopen(true);
    }
  });

  // subscribe to topic 'my/test/topic'
  client.subscribe(postService);
  client.subscribe(notif);
  // client.subscribe("register-service");

  // publish message 'Hello' to topic 'my/test/topic'
  function onClick() {
    const myObject = {
      action: "water-on",
    };
    var payload = JSON.stringify(myObject);

    client.publish(listenService, payload);
    console.log("Published Message");
  }
  function onClickOff() {
    const myObject = {
      action: "water-off",
    };
    var payload = JSON.stringify(myObject);

    client.publish(listenService,payload );
    console.log("Published Message");
  }
  function checkStatus() {
    setloading(true);
    setTimeout(() => {
      // Set loading back to false after the timeout
      setloading(false);
    }, 6000);
    const myObject = {
      action: "valve-status",
    };
    var payload = JSON.stringify(myObject);
    console.log(payload);
    client.publish(listenService, payload);
    console.log("Published Message on ", listenService);
  }

  async function telegramAPI(){
    const url = "https://api.telegram.org/bot1638003720:AAG1JD9I4XjQYEkYiUTa7An3rOGiVk9sq4M/sendMessage?chat_id=-568647766&text=device%20restarted";
    const response = await axios({ url, method:"GET" });
    console.log(response.data);
  }
  function onclose() {
    setopen(false);
    
    
  }

  return (
    <div className="App">
      <header className="App-header">
        <div>
          <LoadingButton
            loading={loading}
            variant="contained"
            onClick={() => checkStatus()}>
            Status
          </LoadingButton>
 
          <Tab />
          <Button variant="contained" color="success" onClick={onClick}>
            Success
          </Button>
          <Tab/>
          <Button variant="contained" color="warning" onClick={onClickOff}>
            Turn Off
          </Button>
        </div>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          key={vertical + horizontal}
          open={open}
          autoHideDuration={6000}
          onClose={onclose}
          message="Lights on">
          <Alert
            onClose={onclose}
            severity={severity}
            sx={{ width: "100%" }}>
            {message}
          </Alert>
        </Snackbar>
      </header>
    </div>
  );
}
